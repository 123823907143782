<template>
  <div>
    <main-nav />
    <main-titlebar myclass="greenBar"/>
       <div class="grid-box-vscale" style="text-align:left;">
        <p>Welkom {{ curUsername }}</p>

      </div>



      <div class="trip-view-row">
        <div class="trip-view-col">
          <router-link to="/trips/new">
            <div class="default-button"><i class="fa-solid fa-plus"></i> Nieuwe rit</div>
          </router-link>
        </div>
      </div>


      <div class="trip-view-row">
        <div class="trip-view-col textAlignCenter">
          <!-- Locatie: {{curGPSpos.lat}} | {{curGPSpos.lng}} -->
          
        </div>
      </div>

         <GMapMap
                :center="{lat: curGPSpos.lat, lng: curGPSpos.lng}"
                :zoom="16"
                map-type-id="terrain"
                style="width: 100%; height: 60vh;"
            >
            <GMapMarker
                  :key="marker.id"
                  v-for="marker in markers"
                  :position="marker.position"
            />
            </GMapMap>

  </div>
</template>

<script>

export default {
  name: 'Home',
  data() {
    var curGPSpos = this.currentLocation();
    if(curGPSpos == null) {
      curGPSpos= {};
      curGPSpos.lat = 0;
      curGPSpos.lng = 0;
    }
    var markers = [{
          position: {
            lat: curGPSpos.lat, lng: curGPSpos.lng
          },
        }];

      return {
          curGPSpos,
          markers,
          logo:require('./../assets/triptrader-logo.png'),
          // curUsername:this.$store.state.auth.user.username
      }
  },

  methods: {


  
  },mounted() {
    // if(this.$store.state.auth.user) {
      // this.curUsername = ref(this.$store.state.auth.user.username);
    // }

  },
  components: {
    'main-titlebar': require('@/components/titlebar.vue').default,
    // 'main-nav': require('@/components/mainmenu_new.vue').default,
    'main-nav': require('@/components/mainmenu.vue').default,
  },
  computed: {
    curUsername() {
      var username = JSON.parse(localStorage.getItem('user')).username;
      return username;
    }
    
  },
   renderTriggered () {
    console.log("renderTriggered: " + this.$store.state.auth.user.username);
    
  }
}
</script>
